import { useRef, useState, useEffect, createElement } from "react";
import ChatList from "./chatList/ChatList";
import { handlePlaySound } from "utils/soundEffect";
import { API_URL } from "shared/api/api.config";
import styles from "./Home.module.scss";
import { IMessage, IOption } from "shared/types/chatWithAssistant";
import { chatConstants } from "shared/constants/chat";
import Button from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useTypedDispatch, useTypedSelector } from "store/store";
import {
  add,
  getGreetingThunk,
  patchLastItemOptions,
  getAssistantResThunk,
  addOptions,
} from "store/chat/chat.slice";
import { scrollBottom } from "utils/srollUtils";
import SpeechRecognationComponent from "./speechRecognation/SpeechRecognation";
import Modal from "components/ui/modal/Modal";
import { ReactComponent as IconRusFlag } from "assets/img/icons/russia-flag.svg";
import { ReactComponent as IconEnFlag } from "assets/img/icons/usa-flag.svg";
import { IChooseLangRes, IGreetingRes } from "shared/types/chatBot";
import { options } from "utils/chatScripts";
import assistantChatApi from "shared/api/chat";
import { LoadingText } from "assets/soundEffects";

const { chooseLang } = assistantChatApi;

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { list: chatList, isLoading } = useTypedSelector((store) => store.chat);
  const { assistant: assistantRes } = useTypedSelector((store) => store.chat);
  const containerRef = useRef<HTMLInputElement>(null);
  const [startListening, setStartListening] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [timeOutSubs, setTimeOutSubs] = useState<NodeJS.Timeout[]>([]);
  const [initGreeting, setInitGreeting] = useState<boolean>(false);
  const [focusOnIframe, setFocusOnIframe] = useState<boolean>(false);
  // const [loadingText, setLoadingText] = useState<boolean>(false);

  const commands = [
    {
      command: /(\b.*)?(russian|russia|русский|россия)(.*\b)?/,
      callback: () => handleChooseLangCommand(chatConstants.RU),
    },
    {
      command: /(\b.*)?(english|england|английский|aнгло)(.*\b)?/,
      callback: () => handleChooseLangCommand(chatConstants.EN),
    },
    {
      command:
        /(\b.*)?(заново|restart|пока|до свидание|начать|start over|назад)(.*\b)?/,
      callback: () => handleStartOverChat(),
    },
  ];

  const handleChooseLangCommand = async (lang: string) => {
    // handlePlaySound("");
    // timeOutSubs.map((s) => clearTimeout(s));
    // if (chatList.length < 1) {
    //   i18next.changeLanguage(lang);
    //   setOpenModal(false);
    //   await greeting();
    //   dispatch(addOptions(options));
    // }
  };

  const handleAssistantRecognition = (message: string) => {
    if (message && chatList.length > 1) {
      // Set a timeout of 3 seconds
      // const timeoutId = setTimeout(() => {
      //   const { audio, audioPlayInstance } = handlePlaySound(LoadingText);
      //   // setLoadingText(true);
      //   audioPlayInstance.then(() => {
      //     const timeOut = setTimeout(() => {
      //       // setLoadingText(false);
      //     }, audio.duration * 1000 + 1000);

      //     setTimeOutSubs((prev) => [...prev, timeOut]);
      //   });
      // }, 3000);

      dispatch(getAssistantResThunk(message)).finally(() => {
        // clearTimeout(timeoutId);
      });

      dispatch(patchLastItemOptions({ newOptions: null }));
      dispatch(add({ text: message, type: "user" }));
    }
  };

  const handleStartOverChat = () => {
    window.location.reload();
  };

  const handleOptionClick = async (option: IOption) => {
    handlePlaySound("");
    dispatch(patchLastItemOptions({ newOptions: null }));
    handleAssistantRecognition(t(option.text));
    timeOutSubs.map((s) => clearTimeout(s));
  };

  const greeting = async () => {
    const res = await dispatch(getGreetingThunk());
    const payload = res.payload as IGreetingRes;
    const url = API_URL + `/chat-bot/api/${payload.audio}`;

    if (payload?.text) dispatch(add({ text: payload.text, type: "assistant" }));

    const { audio, audioPlayInstance } = handlePlaySound(url);

    audioPlayInstance.then(() => {
      const timeOut = setTimeout(() => {
        setStartListening(true);
        dispatch(addOptions(options));
      }, audio.duration * 1000);
      setTimeOutSubs((prev) => [...prev, timeOut]);

      const timeOut2 = setTimeout(() => {
        setFocusOnIframe(true);
      }, (audio.duration / 2.5) * 1000);

      setTimeOutSubs((prev) => [...prev, timeOut2]);
    });
  };

  const chooseLangAudio = async () => {
    const res = await chooseLang();
    const url = API_URL + res.audio;

    const { audio, audioPlayInstance } = handlePlaySound(url);

    audioPlayInstance.then(() => {
      const timeOut = setTimeout(() => {
        setStartListening(true);
      }, audio.duration * 1000);
      setTimeOutSubs((prev) => [...prev, timeOut]);
    });
  };

  const handleOnSpeechRecPlay = () => {
    timeOutSubs.map((s) => clearTimeout(s));
  };

  const handleOnSpeechRecStop = () => {
    setStartListening(false);
  };

  useEffect(() => {
    const { text, audio: audioUrl, option: isOptions } = assistantRes;
    if (
      text
      // && !loadingText
    ) {
      console.log(text);
      const url = `${API_URL}/chat-bot/api/${audioUrl}`;
      const { audio, audioPlayInstance } = handlePlaySound(url);

      audioPlayInstance.then(() => {
        const timeOut = setTimeout(() => {
          setStartListening(true);
        }, audio.duration * 1000);
        setTimeOutSubs((prev) => [...prev, timeOut]);
      });

      dispatch(add({ text, type: "assistant" }));
      if (!isOptions) dispatch(addOptions(options));
    }
  }, [
    assistantRes,
    // loadingText
  ]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      scrollBottom(containerRef);
    }, 500);

    setTimeOutSubs((prev) => [...prev, timeOut]);

    return () => {
      timeOutSubs.map((s) => clearTimeout(s));
    };
  }, [chatList]);

  useEffect(() => {
    if (initGreeting) {
      greeting();
    }
  }, [initGreeting]);

  // useEffect(() => {
  //   if (initGreeting) {
  //     chooseLangAudio();
  //   }
  // }, [initGreeting]);

  return (
    <div
      className={cn(styles.wrapper, focusOnIframe ? styles["active"] : "")}
      onClick={() => setInitGreeting(true)}
    >
      <div className={styles["chat-wrapper"]}>
        <div className={styles["chat-list"]} ref={containerRef}>
          <div className="container">
            <ChatList
              chatList={chatList}
              loading={isLoading}
              onOptionClick={handleOptionClick}
              // loadingText={loadingText}
            />
          </div>
        </div>

        <div className={cn(styles.control, "container")}>
          <SpeechRecognationComponent
            className="container"
            uploadAudioText={handleAssistantRecognition}
            loading={isLoading}
            startListening={startListening}
            onStop={handleOnSpeechRecStop}
            onPlay={handleOnSpeechRecPlay}
            showTranscript={true}
            continuous={false}
            commands={commands}
            // loadingText={loadingText}
          />

          {chatList.length > 1 && (
            <Button
              className={"primary"}
              onClick={handleStartOverChat}
              width="200px"
            >
              {t("Start over")}
            </Button>
          )}
        </div>
      </div>

      <div className={cn(styles["iframe-wrapper"])}>
        <iframe
          name="iframe1"
          id="iframe1"
          src="https://e.customs.gov.kg/passenger-declaration"
          title="Website"
          width="100%"
          height="100%"
        />
      </div>

      {/* <Modal
        onClick={() => setInitGreeting(true)}
        isOpen={openModal}
        setIsOpen={setOpenModal}
        title="Выберите язык / Choose language"
        closeIcon={false}
      >
        <div className={styles.chooseLang}>
          <IconEnFlag
            onClick={(e) => [
              e.stopPropagation(),
              handleChooseLangCommand(chatConstants.EN),
            ]}
          />
          <IconRusFlag
            onClick={(e) => [
              e.stopPropagation(),
              handleChooseLangCommand(chatConstants.RU),
            ]}
          />
        </div>
      </Modal> */}
    </div>
  );
};

export default Home;
