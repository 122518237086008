import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IinitialState } from "./chat.interface";
import { IMessage } from "shared/types/chatWithAssistant";
import nextId from "react-id-generator";
import assistantChatApi from "shared/api/chat";
import { getApiErrorMessage } from "utils/utils";
const { getGreeting, getAssistantResponse } = assistantChatApi;

const initialState: IinitialState = {
  list: [],
  greeting: {
    text: "",
    audio: "",
  },
  assistant: {
    audio: "",
    text: "",
    option: false,
  },
  isLoading: false,
  error: "",
  isSplitScreenActivated: false,
};

export const getGreetingThunk = createAsyncThunk(
  "chat/getChatBotNode",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getGreeting();
      return response;
    } catch (err) {
      return rejectWithValue(getApiErrorMessage(err));
    }
  }
);

export const getAssistantResThunk = createAsyncThunk(
  "chat/getAssistantRes",
  async (message: string, { rejectWithValue }) => {
    try {
      const response = await getAssistantResponse(message);
      return response;
    } catch (err) {
      return rejectWithValue(getApiErrorMessage(err));
    }
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    add: (state, { payload }) => {
      const { text, type, id = nextId() } = payload;
      const newItem: IMessage = { type, text, id };
      state.list = [...state.list, newItem];
    },

    addOptions: (state, { payload }) => {
      const newItem: IMessage = {
        text: "",
        type: "assistant",
        options: payload,
      };

      state.list = [...state.list, newItem];
    },

    patchLastItemOptions: (state, { payload }) => {
      const { newOptions } = payload;
      const lastIndex = state.list.length - 1;
      const newList = state.list.map((item, idx) => {
        return lastIndex === idx ? { ...item, options: newOptions } : item;
      });

      state.list = newList;
    },

    reset: (state) => {},

    setIsSplitScreenActivated: (state, { payload }) => {
      state.isSplitScreenActivated = payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(getGreetingThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGreetingThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) state.greeting = payload;
    });

    builder.addCase(getGreetingThunk.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = state.error;
    });

    builder.addCase(getAssistantResThunk.pending, (state) => {
      state.assistant = {
        audio: "",
        text: "",
        option: false,
      };

      state.isLoading = true;
    });

    builder.addCase(getAssistantResThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) state.assistant = payload;
    });

    builder.addCase(getAssistantResThunk.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = state.error;
      const newItem: IMessage = {
        text: "Error, can you repeat it?",
        type: "error",
      };
      state.list = [...state.list, newItem];
    });
  },
});

export const {
  add,
  addOptions,
  patchLastItemOptions,
  reset,
  setIsSplitScreenActivated,
} = chatSlice.actions;
export default chatSlice.reducer;
