// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_header-1__4VbJw {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.App_show-more-btn__3xUEF {
  background-color: #4b5fa5;
  border-radius: 12px;
  padding: 8px 20px !important;
  text-align: center;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  border: none;
  transition: scale 0.2s;
}
.App_show-more-btn__3xUEF:hover {
  scale: 1.1;
}

.App_wrapper__nlASx {
  background: linear-gradient(212deg, rgb(223, 238, 236) 0%, rgb(253, 242, 227) 100%);
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/utils/_typography.scss","webpack://./src/App.module.scss","webpack://./src/assets/styles/utils/_ui.scss","webpack://./src/assets/styles/utils/_vars.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;ACEF;;ACLA;EACC,yBAAA;EACA,mBAAA;EACA,4BAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;EACA,sBAAA;ADQD;ACNC;EACC,UAAA;ADQF;;AAnBA;EACE,mFEMkB;EFLlB,aAAA;AAsBF","sourcesContent":[".header-1 {\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 29px;\n}\n","@use \"./assets/styles/utils/\" as *;\n\n.wrapper {\n  background: $primary-bg-linear;\n  height: 100vh;\n}\n",".show-more-btn {\n\tbackground-color: #4b5fa5;\n\tborder-radius: 12px;\n\tpadding: 8px 20px !important;\n\ttext-align: center;\n\tcolor: #fff;\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tline-height: 150%;\n\tborder: none;\n\ttransition: scale 0.2s;\n\n\t&:hover {\n\t\tscale: 1.1;\n\t}\n}\n","@import url(\"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap\");\n\n$font-family-primary: \"Poppins\", sans-serif;\n$font-family-secondary: \"Raleway\", sans-serif;\n\n$primary-color: rgb(1, 104, 192);\n$primary-color-light: #fdfdff;\n$primary-color-danger: #ec2300;\n$primary-color-success: #01c067;\n$primary-bg-linear: linear-gradient(\n  212deg,\n  rgba(223, 238, 236, 1) 0%,\n  rgba(253, 242, 227, 1) 100%\n);\n\n$primary-color-border: #d1e8e6;\n\n$primary-transition: all 0.5s ease;\n$primary-gap: 20;\n\n$bg-gray: linear-gradient(0deg, #f5f5f5, #f5f5f5), #fff;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-1": `App_header-1__4VbJw`,
	"show-more-btn": `App_show-more-btn__3xUEF`,
	"wrapper": `App_wrapper__nlASx`
};
export default ___CSS_LOADER_EXPORT___;
