import { IChooseLangRes, IGreetingRes } from "shared/types/chatBot";
import { axiosClient } from "./api.config";
import { getItem } from "services/localStorage.service";
import { IAssistantResponse } from "shared/types/assistant";

const assistantChatApi = {
  getAssistantResponse: async (payload: string) => {
    let lang = getItem("i18nextLng");
    if (!lang) lang = "ru";

    const formData = new FormData();
    formData.set("text", payload);

    const response = await axiosClient.post<IAssistantResponse>(
      `/chat-bot/api/?lang=${lang}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  },

  chooseLang: async () => {
    const response = await axiosClient.get<IChooseLangRes>(
      `/chat-bot/api/lang/`
    );
    return response.data;
  },

  getGreeting: async () => {
    let lang = getItem("i18nextLng");
    if (!lang) lang = "ru";

    const response = await axiosClient.get<IGreetingRes>(
      `/chat-bot/api/hello/?lang=${lang}`
    );
    return response.data;
  },
};

export default assistantChatApi;
