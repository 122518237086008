// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatList_header-1__-LU8X {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.ChatList_show-more-btn__Y4-cJ {
  background-color: #4b5fa5;
  border-radius: 12px;
  padding: 8px 20px !important;
  text-align: center;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  border: none;
  transition: scale 0.2s;
}
.ChatList_show-more-btn__Y4-cJ:hover {
  scale: 1.1;
}

.ChatList_wrapper__1GvKK {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ChatList_options__EFLrf {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: auto;
  cursor: grab;
}
.ChatList_options__EFLrf::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/utils/_typography.scss","webpack://./src/components/screens/home/chatList/ChatList.module.scss","webpack://./src/assets/styles/utils/_ui.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;ACEF;;ACLA;EACC,yBAAA;EACA,mBAAA;EACA,4BAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;EACA,sBAAA;ADQD;ACNC;EACC,UAAA;ADQF;;AAnBA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAsBF;;AAnBA;EACE,aAAA;EACA,SAAA;EACA,iBAAA;EACA,gBAAA;EACA,qBAAA;EACA,YAAA;AAsBF;AApBE;EACE,aAAA;AAsBJ","sourcesContent":[".header-1 {\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 29px;\n}\n","@use \"assets/styles/utils\" as *;\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: $primary-gap + px;\n}\n\n.options {\n  display: flex;\n  gap: calc($primary-gap / 2) + px;\n  flex-wrap: nowrap;\n  overflow-x: auto;\n  scroll-behavior: auto;\n  cursor: grab;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n",".show-more-btn {\n\tbackground-color: #4b5fa5;\n\tborder-radius: 12px;\n\tpadding: 8px 20px !important;\n\ttext-align: center;\n\tcolor: #fff;\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tline-height: 150%;\n\tborder: none;\n\ttransition: scale 0.2s;\n\n\t&:hover {\n\t\tscale: 1.1;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-1": `ChatList_header-1__-LU8X`,
	"show-more-btn": `ChatList_show-more-btn__Y4-cJ`,
	"wrapper": `ChatList_wrapper__1GvKK`,
	"options": `ChatList_options__EFLrf`
};
export default ___CSS_LOADER_EXPORT___;
