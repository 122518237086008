import { HTMLAttributes, FC, Fragment } from "react";
import styles from "./ChatList.module.scss";
import { IMessage, IOption } from "shared/types/chatWithAssistant";
import TypingEffect from "components/ui/typingEffect/TypingEffect";
import Loader from "components/ui/loader/Loader";
import { useTranslation } from "react-i18next";
import ChatCard from "components/screens/home/chatList/chatCard/ChatCard";
import LoadingDots from "components/ui/loadingDots/LoadingDots";

export interface IProps extends HTMLAttributes<HTMLElement> {
  chatList: IMessage[];
  loading: boolean;
  loadingText?: boolean;
  onOptionClick?: (option: IOption) => any;
}

const ChatList: FC<IProps> = ({
  chatList,
  loading,
  loadingText,
  onOptionClick,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <div {...rest} className={styles.wrapper}>
      {chatList.map((message: IMessage, index) => (
        <Fragment key={index}>
          <ChatCardMessages {...message} />
          {message?.options && (
            <ChatCardOptions
              options={message.options}
              onOptionClick={onOptionClick}
            />
          )}
        </Fragment>
      ))}

      {loading && (
        <ChatCard className="loader">
          <Loader loading={true} />
        </ChatCard>
      )}
      {/* 
      {loadingText && (
        <ChatCard className="loader">
          <LoadingDots text={"Wait, the answer is being formed"} />
        </ChatCard>
      )} */}
    </div>
  );
};

export default ChatList;

const ChatCardMessages: FC<any> = ({ text, id, type }) => {
  const { t } = useTranslation();

  return text ? (
    <ChatCard key={id} className={type ?? ""}>
      {text.split("/n").map((txt, index) => (
        <TypingEffect text={t(txt)} duration={2} key={index} />
      ))}
    </ChatCard>
  ) : null;
};

const ChatCardOptions: FC<{
  options: IOption[];
  onOptionClick?: (option: IOption) => any;
}> = ({ options, onOptionClick }) => {
  const { t } = useTranslation();

  const handleOptionClick = (option: IOption) => {
    onOptionClick && onOptionClick(option);
  };

  return options?.length > 0 ? (
    <div className={styles.options}>
      {options.map((option: IOption, index) => (
        <ChatCard
          style={{ whiteSpace: "nowrap" }}
          className={option?.type ?? ""}
          key={index}
          onClick={() =>
            onOptionClick ? handleOptionClick(option) : undefined
          }
        >
          {t(option.text)}
        </ChatCard>
      ))}
    </div>
  ) : null;
};
