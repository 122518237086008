import Routing from "routing/Routing";
import styles from "./App.module.scss";
import { setItem } from "services/localStorage.service";
import { useConstructor } from "hooks/useConstructor";

function App() {
  useConstructor(() => {
    setItem("i18nextLng", "");
  });

  return (
    <div className={styles.wrapper}>
      <Routing />
    </div>
  );
}

export default App;
