import { IOption } from "shared/types/chatWithAssistant";

const options: IOption[] = [
  { text: "Weather", type: "option" },
  { text: "Time", type: "option" },
  { text: "Exchange rate", type: "option" },
  { text: "Where is the pharmacy", type: "option" },
  { text: "Where is the medical center", type: "option" },
  { text: "Where are the banks", type: "option" },
  { text: "Where is the toilet", type: "option" },
];

export { options };
